import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import typy from 'typy'

import * as styles from '../assets/css/about.module.css'
import SmartLink from '../components/SmartLink'
import Layout from '../components/Layout'

const essayQuery = graphql`
  query {
    content(uid: {eq: "c8"}) {
      items {
        header
        content
        images {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
    }
  }
`

const aboutUsGroups = (items) => {
  const cell = []
  items.forEach((item, index) => {
    cell.push(<div key={item.header}><GatsbyImage image={getImage(item.images[0])} /></div>)
    cell.push(<div key={index}>
      <div dangerouslySetInnerHTML={{ __html: item.header }}></div>
      <div dangerouslySetInnerHTML={{ __html: item.content }} />
    </div>)
  })
  return cell
}

const About = () => {
  const { content } = useStaticQuery(essayQuery)
  const contentItems = typy(content, 'items').safeArray
  const aboutItem = contentItems.slice(0, 1)[0]
  const groups = contentItems.slice(1)
  return (
    <Layout>
      <section>
        <h1>{aboutItem.header}</h1>
        <div dangerouslySetInnerHTML={{ __html: aboutItem.content }} />
        <div className={styles.aboutUs}>
          {aboutUsGroups(groups)}
        </div>
      </section>
    </Layout>
  )
}

export default About
